<template>
  <div class="uesrCenter">
    <div class="banner flex-y-s">
      <a href="javasctipt:;" @click="backLevel" class="backBtn">返回</a>
      <a class="homeBtn" @click="backHome" href="javascript:;">首页</a>
      <div>
        <!-- <h3>名称名称</h3> -->
        <p>欢迎您！{{ $store.state.globalInfo.nickname }}</p>
      </div>
    </div>
    <ul>
      <li>
        <div>
          <img src="../../assets/images/userCenter/qy.png" alt="" />
          我的企业
        </div>
        <div>
          <img src="../../assets/images/userCenter/bz.png" alt="" />
          企业群组
        </div>
      </li>
      <div class="box">
        <div class="name">{{ this.wsiCompanyName }}</div>
        <div class="title">登记信息：</div>
        <div class="list">
          <div class="item">
            <span class="text">企业名称</span>
            <i class="symbol">:</i>
            <span class="text">{{ this.wsiCompanyName }}</span>
          </div>
          <div class="item">
            <span class="text">统一社会信用代码</span>
            <i class="symbol">:</i>
            <span class="text">{{ this.wsiShxydm }}</span>
          </div>
          <div class="item">
            <span class="text">法定代表人</span>
            <i class="symbol">:</i>
            <span class="text">{{ this.fddbr }}</span>
          </div>
          <div class="item">
            <span class="text">住所</span>
            <i class="symbol">:</i>
            <span class="text">{{ this.zs }}</span>
          </div>
          <div class="item">
            <span class="text">经营范围</span>
            <i class="symbol">:</i>
            <span
              class="text ellipsis"
              :class="{ textCss: !display ? true : false }"
            >
              <span class="open" @click="fold()" v-show="display == true"
                >展开</span
              >
              <span>{{ this.jyfw }}</span>
              <span class="fold" @click="fold()" v-show="display == false"
                >收起</span
              ></span
            >
          </div>
          <div class="item">
            <span class="text">行业</span>
            <i class="symbol">:</i>
            <span class="text">{{ this.hy }}</span>
          </div>
          <div class="item">
            <span class="text">注册资本</span>
            <i class="symbol">:</i>
            <span class="text">{{ this.zczb }}（万元）</span>
          </div>
        </div>
        <div class="title">信息完善：（为便于日常管理，请按实际情况填写）</div>
        <van-form @submit="onSubmit" @failed="onFailed" refs="form">
          <div class="van-item">
            <van-field
              name="wsiIfNormalDevelopment"
              label="是否开展经营活动："
              class="flex-block flex-block-css"
              required
            >
              <template #input>
                <van-radio-group
                  v-model="wsiIfNormalDevelopment"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              class="flex-block"
              name="wsiReasonOne"
              placeholder="请输入原因"
              v-model="wsiReasonOne"
              v-if="wsiIfNormalDevelopment == '2'"
              :rules="[{ required: true, message: '请输入原因' }]"
            />
          </div>
          <div class="van-item">
            <van-field
              name="wsiOperatorConsistent"
              label="实际经营者与执照信息是否一致："
              class="flex-block flex-block-css"
              required
            >
              <template #input>
                <van-radio-group
                  v-model="wsiOperatorConsistent"
                  direction="horizontal"
                  @change="change"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              label="请输入实际经营者姓名："
              required
              class="flex-block"
              name="wsiRealName"
              v-model="wsiRealName"
              :rules="[{ required: true, message: '请输入实际经营者姓名' }]"
            />
            <van-field
              label="请输入实际经营者联系电话："
              required
              class="flex-block"
              name="wsiRealPhone"
              v-model="wsiRealPhone"
              :rules="[{ required: true, message: '请输入实际经营者联系电话' }]"
            />
          </div>
          <div class="van-item">
            <van-field
              name="wsiPlaceConsistent"
              label="实际经营地址是否与登记地址一致："
              class="flex-block flex-block-css"
              required
            >
              <template #input>
                <van-radio-group
                  v-model="wsiPlaceConsistent"
                  direction="horizontal"
                  @change="change2"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              label="请录入实际经营地址："
              required
              class="flex-block"
              name="wsiActualPlace"
              :clearable="true"
              v-model="wsiActualPlace"
              v-if="wsiPlaceConsistent == '2'"
              :rules="[{ required: true, message: '请输入实际经营地址' }]"
            />
            <div class="latLng" v-if="wsiPlaceConsistent == '2'">
              <span>纬度：{{ wsiLatitude }}</span>
              <span>经度：{{ wsiLongitude }}</span>
            </div>
            <Map
              @search="search"
              @initSearch="initSearch"
              @lngLat="lngLat"
              v-if="wsiPlaceConsistent == '2'"
              :locationData="locationData"
            />
          </div>
          <div class="van-item">
            <van-field
              name="wsiPlaceSource"
              label="经营场地来源："
              class="flex-block flex-block-css"
              required
            >
              <template #input>
                <van-radio-group
                  v-model="wsiPlaceSource"
                  direction="horizontal"
                >
                  <van-radio name="1">租赁</van-radio>
                  <van-radio name="2">自有</van-radio>
                </van-radio-group>
              </template>
            </van-field>
          </div>
          <div class="van-item" v-if="wsiPlaceSource == '1'">
            <van-field
              required
              name="wsiLandlordMessage"
              v-model="wsiLandlordMessage"
              class="flex-block"
              label="请录入房东信息："
              :rules="[
                {
                  required: true,
                  message: '请输入房东信息',
                },
              ]"
            />
          </div>
          <div class="van-item">
            <van-field
              name="wsiIfSelfHouse"
              label="经营场所是否为自建房："
              class="flex-block flex-block-css"
              required
            >
              <template #input>
                <van-radio-group
                  v-model="wsiIfSelfHouse"
                  direction="horizontal"
                >
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <p class="notice">
              （自建房：城乡居民未经专业设计、专业施工在集体土地（含宅基地）上自行建造的房屋）
            </p>
          </div>
          <div class="van-item" v-if="wsiIfSelfHouse == '1'">
            <van-field
              name="wsiIfSafety"
              label="是否已经安全评估："
              class="flex-block flex-block-css"
              required
            >
              <template #input>
                <van-radio-group v-model="wsiIfSafety" direction="horizontal">
                  <van-radio name="1">是</van-radio>
                  <van-radio name="2">否</van-radio>
                </van-radio-group>
              </template>
            </van-field>
          </div>
          <div
            class="van-item"
            :class="{ picClass: wsiPicturePath.length == 0 ? false : true }"
          >
            <van-field
              name="wsiPicturePath"
              label="店照（门头）照片上传"
              required
              class="flex-block flex-block-css"
            >
              <template #input>
                <van-uploader
                  upload-icon="photograph"
                  v-model="wsiPicturePath"
                  :after-read="afterRead"
                  accept="image/*"
                  @oversize="onOversize"
                  :max-size="2 * 1024 * 1024"
                  :max-count="1"
                />
              </template>
            </van-field>
            <!-- <img
              src="@/assets/images/camera.png"
              alt=""
              class="camera"
              v-if="wsiPicturePath.length == 0"
            />
            <img
              src="@/assets/images/close.png"
              alt=""
              class="close"
              v-if="wsiPicturePath.length"
            /> -->
          </div>
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
    </ul>
  </div>
</template>

<script>
import {
  selectOneEnterpriseSupervisionByShxydm,
  perfectWechatAccount,
  getWechatEnterpriseSupervisionCount,
} from "../../api/home";
import { latLng } from "@/api/map";
import {
  getSupplementaryInformationByShxydm,
  saveWeSupplementaryInformation,
  getFillData,
  upload,
} from "../../api/infoComplete";
import AMap from "AMap";
import axios from "axios";
import map from "@/views/map2";
import { Toast } from "vant";
export default {
  components: {
    Map: map,
  },
  data() {
    return {
      username: "",
      wsiPicturePath: [],
      wsiIfNormalDevelopment: "",
      wsiOperatorConsistent: "",
      wsiPlaceConsistent: "",
      wsiResidenceStandard: "",
      wsiPoliticalOutlook: "",
      landlordInfo: "",
      sinceBuild: "",
      wsiPlaceSource: "",
      wsiEducation: "",
      wsiIfSelfHouse: "",
      wsiReasonOne: "",
      wsiReasonTwo: "",
      wsiRealPhone: "",
      wsiRealName: "",
      wsiActualPlace: "",
      wsiRealPlace: "",
      value5Result: "",
      value6Result: "",
      value7Result: "",
      wsiRemark: "",
      wsiDisposal: "",
      wsiIfSafety: "",
      valueActive: "",
      columns: ["是", "否"],
      display: true,
      showPicker: false,
      fieldValue: "",
      cascaderValue: "",
      info: "",
      wsiShxydm: "", //社会信用代码
      fddbr: "", //法定代表人
      zs: "", //住所
      jyfw: "", //经营范围
      hy: "", //行业
      zczb: "", //注册资本
      wsiCompanyName: "", //公司名称
      waPerfect: "", //是否完善 1为完善
      waOperator: "", //实际经营者
      waOperatorPhone: "", //实际经营者联系电话
      waPremises: "", //实际经营场所
      waProject: "", //实际经营项目
      address: "",
      waUuid: "",
      wsiLandlordMessage: "", //党员数量
      wsiLongitude: "", //经度
      wsiLatitude: "", //纬度
      locationData: { lat: "", lng: "" },
      pic: "",
      echoPic: "",
    };
  },
  mounted() {
    this.info = JSON.parse(this.$route.query.item);
    this.wsiShxydm = this.info.waShxydm;
    this.waUuid = this.info.waUuid;
    // this.waPerfect = this.info.waPerfect;
    this.getData();
  },
  methods: {
    change(val) {
      if (val == "1") {
        getFillData({ wsiShxydm: this.wsiShxydm }).then((res) => {
          if (res.success) {
            this.wsiRealName = res.data.jyz;
            this.wsiRealPhone = res.data.phone;
          }
        });
      } else {
        this.wsiRealName = "";
        this.wsiRealPhone = "";
      }
    },
    change2(val) {
      if (val == "1") {
        getFillData({ wsiShxydm: this.wsiShxydm }).then((res) => {
          if (res.success) {
            this.wsiActualPlace = res.data.jydz;
          }
        });
      } else {
        this.wsiActualPlace = "";
      }
    },
    //正向地理编码，将地址描述信息转换成地理坐标（经纬度）
    handleLocation(address) {
      let that = this;
      AMap.plugin("AMap.Geocoder", function () {
        let geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: "all",
        });
        geocoder.getLocation(address, (status, result) => {
          if (status === "complete" && result.info === "OK") {
            that.wsiLatitude = result.geocodes[0].location.lat;
            that.wsiLongitude = result.geocodes[0].location.lng;
            that.locationData = {
              lat: that.wsiLatitude,
              lng: that.wsiLongitude,
            };
          }
        });
      });
    },
    //逆向地理编码：将地理坐标（经纬度）转换成地址描述信息
    handleAddress(lnglatXY) {
      let that = this;
      AMap.plugin("AMap.Geocoder", function () {
        let geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: "all",
        });
        geocoder.getAddress(lnglatXY, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            that.wsiActualPlace = result.regeocode.formattedAddress;
            that.wsiLongitude = result.regeocode.aois[0].location.lng;
            that.wsiLatitude = result.regeocode.aois[0].location.lat;
          }
        });
      });
    },
    lngLat(val) {
      this.handleAddress(val);
    },
    initSearch(val) {
      if (val) {
        this.wsiActualPlace = val.formattedAddress;
        this.wsiLongitude = val.position.lng;
        this.wsiLatitude = val.position.lat;
      }
    },
    search() {
      this.handleLocation(this.wsiActualPlace);
      // latLng({
      //   address: this.wsiActualPlace,
      //   key: "LCJBZ-KMU63-QJH3C-3TTPS-4KQQ3-WZFIP",
      // }).then((res) => {
      //   if (res.status == 0) {
      //     this.wsiLongitude = res.result.location.lng;
      //     this.wsiLatitude = res.result.location.lat;
      //     this.locationData = { lng: this.wsiLongitude, lat: this.wsiLatitude };
      //   }
      //   if (res.status == 347) {
      //     this.$toast(`${res.message}，请输入详细地址`);
      //   }
      // });
    },
    location(res) {
      this.wsiLongitude = res.result.ad_info.location.lng;
      this.wsiLatitude = res.result.ad_info.location.lat;
      this.detailAddress = res.result.address;
      this.wsiActualPlace = res.result.address;
    },
    afterRead(file) {
      let formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code == 200) {
          this.pic = res.data;
        }
      });
    },
    onOversize(file) {
      Toast("最大上传2M");
    },
    getData() {
      getSupplementaryInformationByShxydm({
        wsiShxydm: this.wsiShxydm,
      }).then((res) => {
        if (res.success) {
          this.wsiIfNormalDevelopment = res.data.wsiIfNormalDevelopment;
          this.wsiReasonOne = res.data.wsiReasonOne;
          this.wsiOperatorConsistent = res.data.wsiOperatorConsistent;
          this.wsiReasonTwo = res.data.wsiReasonTwo;
          this.wsiPlaceConsistent = res.data.wsiPlaceConsistent;
          this.wsiLongitude = res.data.wsiLongitude;
          this.wsiLatitude = res.data.wsiLatitude;
          this.wsiResidenceStandard = res.data.wsiResidenceStandard;
          this.wsiRealPlace = res.data.wsiRealPlace;
          this.wsiDisposal = res.data.wsiDisposal;
          this.wsiIfSafety = res.data.wsiIfSafety;
          this.wsiPoliticalOutlook = res.data.wsiPoliticalOutlook;
          this.wsiEducation = res.data.wsiEducation;
          this.wsiIfSelfHouse = res.data.wsiIfSelfHouse;
          this.wsiLandlordMessage = res.data.wsiLandlordMessage;
          this.wsiRemark = res.data.wsiRemark;
          this.wsiPlaceSource = res.data.wsiPlaceSource;
          this.wsiActualPlace = res.data.wsiActualPlace;
          this.echoPic = res.data.wsiPicturePath;
          this.wsiPicturePath = [
            {
              url: res.data.wsiPicturePath,
            },
          ];
          this.locationData = { lng: this.wsiLongitude, lat: this.wsiLatitude };
        }
      });
      selectOneEnterpriseSupervisionByShxydm({
        shxydm: this.wsiShxydm,
      }).then((res) => {
        this.fddbr = res.data.legalRepresentative || "";
        this.zs = res.data.residence || "";
        this.jyfw = res.data.jyfw || "";
        this.hy = res.data.hy || "";
        this.zczb = res.data.zczb || "";
        this.wsiCompanyName = res.data.enterpriseName || "";
        // this.wsiShxydm = res.data.shxydm || "";
      });
      // getWechatEnterpriseSupervisionCount({
      //   waShxydm: this.wsiShxydm,
      // }).then((res) => {
      //   this.waOperator = res.data[0].waOperator;
      //   this.waOperatorPhone = res.data[0].waOperatorPhone;
      //   this.waPremises = res.data[0].waPremises;
      //   this.waProject = res.data[0].waProject;
      // });
    },
    backLevel(e) {
      e.preventDefault();
      this.$router.go(-1);
      return false;
    },
    fold() {
      this.display = !this.display;
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    onSubmit(values) {
      values.wsiLongitude = this.wsiLongitude;
      values.wsiLatitude = this.wsiLatitude;
      values.wsiPicturePath = this.pic;
      values.wsiShxydm = this.wsiShxydm;
      values.wsiCompanyName = this.wsiCompanyName;
      saveWeSupplementaryInformation(values).then((res) => {
        if (res.success) {
          Toast.success(res.data);
          this.$router.go(-1);
        }
      });

      // var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      // if (!myreg.test(this.waOperatorPhone)) {
      //   Toast.fail("请填写正确手机号");
      //   return false;
      // }
      // perfectWechatAccount({
      //   waUuid: this.waUuid,
      //   waOperator: this.waOperator,
      //   waOperatorPhone: this.waOperatorPhone,
      //   waPremises:
      //     this.fieldValue.split("/").join("") +
      //     this.wsiOperatorConsistent +
      //     this.address,
      //   waProject: this.waProject,
      //   waPerfect: "1",
      // }).then((res) => {
      //   if (res.success) {
      //     this.$router.push({
      //       path: "/infoList",
      //     });
      //   }
      // });
    },
    backHome(e) {
      e.preventDefault();
      this.$router.push({ path: "/" });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.van-item {
  /deep/.van-uploader__upload {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: rem(40);
      height: rem(40);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url(~@/assets/images/camera.png) no-repeat center center;
      background-size: 100% 100%;
      pointer-events: none;
    }
  }
  &.picClass {
    /deep/.van-uploader__upload {
      &::before {
        display: none;
      }
    }
  }
}
/deep/.van-uploader__preview-delete {
  &::before {
    content: "";
    position: absolute;
    width: rem(20);
    height: rem(20);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url(~@/assets/images/close.png) no-repeat center center;
    background-size: 100% 100%;
    pointer-events: none;
  }
}

.camera {
  position: absolute;
  width: rem(40);
  height: rem(40);
  left: rem(335);
  top: rem(65);
  pointer-events: none;
}
.close {
  position: absolute;
  width: rem(20);
  height: rem(20);
  left: rem(420);
  top: rem(10);
  pointer-events: none;
}
.flex-block {
  display: block;
  padding-bottom: 0;
  ::v-deep.van-field__label {
    width: 100%;
    font-size: rem(28);
    color: #333333;
    margin-bottom: 5px;
    text-indent: 10px;
    padding: 0 0 0 rem(10);
  }
}
.flex-block-css {
  display: flex;
  align-items: center;
  /deep/.van-field__label {
    width: auto;
  }
  ::v-deep .van-field__body {
    background: none;
  }
  &.van-cell {
    &::after {
      border: none;
    }
  }
}
.notice {
  color: red;
  text-align: left;
  margin: rem(10) 0 0;
}
.van-item {
  margin: 0 0 rem(10);
  padding: 0 0 rem(10);
  position: relative;
  // border-bottom: rem(1) solid #ebedf0;
}
::v-deep .van-field__body {
  background: rgba(238, 238, 238, 0.6);
  border-radius: rem(4);
  width: 100%;
  min-height: rem(66);
}
::v-deep .van-cell__value {
  display: flex;
  flex-wrap: wrap !important;
  align-items: center;
}
/deep/ .van-icon::before {
  display: none;
}
/deep/ .van-cell {
  padding: rem(10) 0 0;
  .van-cell__title,
  .van-cell__value {
    text-align: left;
    span {
      font-size: rem(24);
      color: #333;
    }
  }
  .van-cell__right-icon {
    display: none;
  }
  .van-cell__title {
    span {
      font-weight: bold;
    }
  }
}
i {
  font-style: normal;
}
.banner {
  padding: rem(20);
  width: 100%;
  height: rem(342);
  background: url("../../assets/images/userCenter/banner-bg2.png");
  background-size: 100%;
  color: white;
  .title {
    color: #fff;
    font-size: rem(40);
  }
  p {
    font-size: rem(36);
    font-weight: bold;
  }
  img {
    width: rem(36);
    height: rem(36);
  }
}
ul {
  margin: rem(20) auto;
  box-sizing: border-box;
  width: rem(686);
  position: relative;
  top: -40px;
  text-align: center;

  li {
    width: 100%;
    display: flex;
    img {
      width: rem(30);
      height: rem(32);
      position: relative;
      top: 2px;
    }
    div {
      width: 50%;
      text-align: center;
      padding: rem(25) 0;
    }
  }
  .blod {
    font-size: rem(24);
    color: #3296fa;
  }
  & > li:first-child {
    background: #fafdff;
    height: rem(96);
    box-shadow: 0 rem(2) rem(16) 0 rgba(0, 0, 0, 0.09);
    border-radius: rem(48);
    position: relative;
    & > div:first-child::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 14px;
      display: block;
      width: 2px;
      height: 18px;
      background: #3296fa;
    }
  }
}
.box {
  .name {
    padding: rem(20) rem(30);
    background: url("../../assets/images/userCenter/item-bg.png");
    background-size: 100% 100%;
    font-weight: bold;
    font-size: rem(36);
    color: white;
  }
  .title {
    font-size: rem(28);
    color: #333;
    padding: rem(20) 0;
    text-align: left;
    font-weight: bold;
  }
  .list {
    .item {
      display: flex;
      align-items: baseline;
      margin: 0 0 rem(10);
      .text {
        font-size: rem(24);
        color: #333;
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
        &:nth-of-type(1) {
          width: rem(150);
          text-align: justify;
          text-align-last: justify;
          flex: 0 0 auto;
          font-weight: bold;
        }
      }
      .symbol {
        font-size: rem(30);
        color: #666;
        margin: 0 rem(10);
      }
    }
  }
}
/deep/ .van-cell {
  &::after {
    left: 0;
    right: 0;
  }
  .van-field__body {
    .van-field__control {
      font-size: rem(26);
      padding: 0 rem(20);
      //   border: rem(1) solid #ddd;
    }
  }
  .van-field__label {
    position: relative;
    margin: 0;
    span {
      font-size: rem(24);
      color: #333;
    }
  }
}
::v-deep .van-field__error-message {
  // display: none !important;
}
::v-deep .van-field__label::after {
  content: "";
  position: absolute;
  left: rem(20);
  top: 50%;
  transform: translateY(-50%);
  width: rem(6);
  height: rem(28);
  background-image: linear-gradient(179deg, #2d90fb 0%, #84bfff 100%);
  border-radius: 40px;
}
/deep/ .van-cell--required::before {
  left: 0;
}
.form1 {
  /deep/.van-field__label {
    padding: 0;
  }
  ::v-deep.van-cell--required .van-field__label::after {
    left: rem(0);
  }
  .van-cell--required::before {
    display: none;
  }
}
.ellipsis {
  position: relative;
  text-align: justify;
  line-height: 18px;
  max-height: 54px;
  display: block !important;
  &::before {
    content: "";
    height: 36px;
    width: 0;
    float: right;
    background: red;
  }
  &.textCss {
    max-height: none;
  }
  .fold {
    font-size: rem(24);
    color: #3a52f0;
  }
  .open {
    font-size: rem(24);
    color: #3a52f0;
    float: right;
    clear: both;
  }
}
/deep/.van-icon.van-icon-clear {
  &::before {
    height: rem(32) !important;
    font-size: rem(20) !important;
    display: block !important;
  }
}
.latLng {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: rem(10) 0;
  padding: 0 rem(20);
  span {
    &:nth-of-type(1) {
      margin: 0 rem(20) 0 0;
    }
  }
}
</style>
