<template>
  <div style="position: relative">
    <van-button
      type="primary"
      native-type="button"
      style="background: blue"
      @click="toDetail"
      >搜索</van-button
    >
    <div id="container" style="width: 100%; height: 50vh"></div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { unLatLng } from "@/api/map";

import AMap from "AMap";
export default {
  data() {
    return {
      MAP: null,
      geolocation: null,
      mark: undefined,
      target: undefined,
      latitude: undefined,
      longitude: undefined,
    };
  },
  props: {
    locationData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    locationData: {
      handler(val) {
        this.searchAddress(val);
      },
      // immediate: true,
      deep: true,
    },
  },
  methods: {
    init() {
      this.MAP = new AMap.Map("container", {
        resizeEnable: true, //设置地图可缩放
        zoom: 16, //设置地图的层级
        center: [117.000923, 36.675807],
      });
      let that = this;

      AMap.plugin("AMap.Geolocation", function () {
        let geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：无穷大
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: "LB", //定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        that.MAP.addControl(geolocation);
        geolocation.getCurrentPosition();
        if (!that.locationData.lng || !that.locationData.lat) {
          AMap.event.addListener(geolocation, "complete", (e) => {
            that.$emit("initSearch", e);
          }); //返回定位信息
          AMap.event.addListener(geolocation, "error", () => {}); //返回定位出错信息
        } else {
          that.searchAddress(that.locationData);
        }
      });

      AMap.event.addListener(this.MAP, "click", function (e) {
        that.mark && that.MAP.remove(that.mark);
        that.mark = new AMap.Marker({
          position: [e.lnglat.lng, e.lnglat.lat], //位置
        });
        that.MAP.add(that.mark);
        that.MAP.setCenter([e.lnglat.lng, e.lnglat.lat]);
        that.$emit("lngLat", [e.lnglat.lng, e.lnglat.lat]);
      });
    },
    searchAddress(val) {
      let that = this;
      that.mark && that.MAP.remove(that.mark);
      that.mark = new AMap.Marker({
        position: [val.lng, val.lat], //位置
      });
      that.MAP.add(that.mark);
      that.MAP.setCenter([val.lng, val.lat]);
    },
    toDetail() {
      this.$emit("search");
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99;
}
/deep/.smnoprint {
  display: none;
}
/deep/.amap-logo {
  display: none;
  opacity: 0 !important;
}
/deep/.amap-copyright {
  opacity: 0;
}
</style>
