import request from "@/utils/request.js";
//完善信息保存及修改
export function saveWeSupplementaryInformation(data) {
  return request({
    method: "post",
    url: "/public/SupplementaryInformation/saveData",
    data: data,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}
//根据社会信用代码获取完善信息
export function getSupplementaryInformationByShxydm(data) {
  return request({
    method: "post",
    url: "/public/SupplementaryInformation/getSupplementaryInformationByShxydm",
    data: data,
  });
}
export function getFillData(data) {
  return request({
    method: "post",
    url: "/public/SupplementaryInformation/getFillData",
    data: data,
  });
}
export function upload(data) {
  return request({
    method: "post",
    url: "/public/SupplementaryInformation/upload",
    data: data,
  });
}
